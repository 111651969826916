#ConfianEnNosotros {
    height: 200px;
    width: 100%;

    margin-top: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
}
#ConfianEnNosotros h3{
    width: 100%;
    margin-bottom: 50px;
    margin-left: 0;
    padding-left: 0;

    font-size: 2.5rem;
    color: var(--GrisClaro);
    text-align: center;
    
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);

    transition: all 0.5s;
}
.swiper-slide img:hover{
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
}

/* .swiper-slide img {
    height: 100%;
    width: auto;
} */