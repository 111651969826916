#header{
    width: 100%;
    height: 100vh;

    background-color: rgb(83, 83, 83);
    background-image: url("./imgs/fondo_header.jpg");
    background-position: center;
    background-size: cover;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#header h1 {
    margin-top: 250px;
    text-transform: uppercase;
    text-align: center;
    color: white;
    font-size: 6rem;

    z-index: 2;
}
#header img {
    height: 35vh;
    z-index: 2;
}

#header .sombra{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    background-color: hsla(207, 16%, 11%, 0.514);
}

#header .lineas{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    opacity: 50%;
}

#header .gloss {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    height: 100%;
    width: 100%;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

#header .loading{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: fadeInAnimation ease 4s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
     }
}
#header .loading h1{
    color: var(--Rojo);
}
#header .loading .sombra{
    background-color: var(--GrisOscuro);
    opacity: 0.95;
}

@media (max-width: 1400px) {
    #header h1 {
        margin-top: 250px;
        text-transform: uppercase;
        text-align: center;
        color: white;
        font-size: 4rem;
    
        z-index: 2;
    }
}

@media (max-width: 500px) {
    #header h1 {
        margin-top: 150px;
        font-size: 3rem;
    }
}
