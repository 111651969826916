#ConfianEnNosotros {
    height: 200px;
    width: 100%;

    margin-top: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;
}
#ConfianEnNosotros h3{
    width: 100%;
    margin-bottom: 50px;
    margin-left: 0;
    padding-left: 0;

    font-size: 2.5rem;
    color: var(--GrisClaro);
    text-align: center;
    
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
} */
.swiper-slide a{
    height: 100%;
    width: auto;

    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-slide a.producto{
    height: auto;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}
